<template>
  <div class="role">
    <query-header :handle-click-add-btn="handleClickAddBtn" :handle-query="handleQuery" :handle-refresh="getRoleList"
                  :query="queryForm" :show-add-btn="userInfo.authArr.includes('SYSTEM_ROLE_CREATE')"
                  :showSelect="false" add-btn-text="创建角色" placeholder="请输入关键字查询"/>
    <a-table ref="roleListRef" :columns="roleColumns" :data-source="roleList" :pagination="false"
             :rowKey="record=>record.id" size="small">
      <template slot="action" slot-scope="record">
        <a-button v-if="userInfo.authArr.includes('SYSTEM_ROLE_UPDATE')" type="link" @click="handleEditRole(record)">
          编辑
        </a-button>
        <a-popconfirm v-if="userInfo.authArr.includes('SYSTEM_ROLE_DELETE')" cancel-text="取消" ok-text="确认" title="确定删除该角色吗？"
                      @confirm="handleDeleteRole(record)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="showAddNewRoleModal" :title="isEditing ? '编辑角色' : '创建角色'" :width="548" :mask-closable="false">
      <div v-if="showAddNewRoleModal" class="new-role-modal">
        <section>
          <a-form-model ref="addNewRoleForm" :label-col="labelCol"
                        :model="newRole"
                        :rules="rules" :wrapper-col="wrapperCol">
            <a-form-model-item label="角色名称" prop="roleName">
              <a-input :disabled="isEditing" v-model="newRole.roleName" style="width: 420px;"/>
            </a-form-model-item>
          </a-form-model>
        </section>
        <div class="auth">
          <div class="title">角色权限</div>
          <div class="all">
            <a-checkbox :checked="checkedAll || (checkedKeys.length === allAuthKeys.length)"
                        :indeterminate="(checkedKeys.length < allAuthKeys.length) && checkedKeys.length !== 0"
                        @change="handleCheckBoxChange">全选
            </a-checkbox>
          </div>
          <div class="content">
            <a-tree v-model="checkedKeys" :default-checked-keys="checkedKeys" :default-selected-key="checkedKeys"
                    :tree-data="treeData"
                    checkable @check="handleTreeCheck"
            />
          </div>
        </div>
        <div class="footer">
          <a-button type="primary" @click="handleAddRole">{{ isEditing ? '修改' : '创建' }}</a-button>
          <a-button @click="hideAddNewRoleModal">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import QueryHeader from '@views/system/components/QueryHeader.vue';
import { mapState } from 'vuex';

export default {
  name: 'Index',
  components: { QueryHeader },
  computed: {
    roleColumns() {
      const { query } = this.roleFilterInfo;
      return [
        {
          title: '角色名称',
          key: 'roleName',
          filteredValue: [query] || null,
          onFilter: (value, record) => record.roleName.includes(value),
          dataIndex: 'roleName'
        }, {
          title: '操作',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ];
    },
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  watch: {
    showAddNewRoleModal(value) {
      if (!value) {
        this.checkedKeys = [];
        this.newRole = { roleName: '' };
        this.checkedAll = false;
      }
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      isEditing: false,
      mustCheckedKeys: [],
      rules: {
        roleName: [
          {
            required: true,
            trigger: 'blur',
            message: '角色名称不能为空'
          }
        ]
      },
      checkedKeys: [],
      newRole: {
        roleName: ''
      },
      treeData: [],
      queryForm: { text: '' },
      showAddNewRoleModal: false,
      roleList: [],
      roleListMap: {},
      selectOptions: [],
      roleFilterInfo: {
        query: ''
      },
      checkedAll: false,
      allAuthKeys: []
    };
  },
  methods: {
    handleCheckBoxChange(e) {
      const { checked } = e.target;

      if (checked) {
        this.checkedKeys = [...this.allAuthKeys];
      } else {
        this.checkedKeys = [...this.mustCheckedKeys];
      }
      this.checkedAll = e.target.checked;
    },
    handleEditRole(record) {
      this.isEditing = true;
      this.showAddNewRoleModal = true;
      this.newRole = cloneDeep(record);
      this.checkedKeys = [...record.roleLabels];
      this.getAllAuthLabel(true);
    },
    handleTreeCheck(checkedKeys) {
      const set = new Set([...checkedKeys, ...this.mustCheckedKeys]);
      this.checkedKeys = Array.from(set);
    },
    handleAddRole() {
      this.$refs.addNewRoleForm.validate(async (valid) => {
        if (valid) {
          if (!this.checkedKeys.length) {
            this.$Message.error('请选择权限');
            return;
          }
          const authLabelList = this.checkedKeys.filter((key) => key.includes('_'));
          const data = {
            roleName: this.newRole.roleName,
            authLabelList
          };

          let res;
          if (!this.isEditing) {
            res = await this.$services.createRole({
              data,
              msg: '添加角色成功'
            });
          } else {
            data.roleId = this.newRole.id;
            res = await this.$services.updateRole({
              data,
              msg: '修改角色成功'
            });
          }

          if (res.success) {
            await this.getRoleList();
            this.hideAddNewRoleModal();
          }
        }
      });
    },
    hideAddNewRoleModal() {
      this.isEditing = false;
      this.showAddNewRoleModal = false;
    },
    async getAllAuthLabel(edit = false) {
      const subAccountRes = await this.$services.listAllAuthLabel();
      const temp = {};
      const allAuthKeys = [];
      const tempTreeData = [];
      const mustCheckedKeys = [];
      if (subAccountRes.success) {
        const { data } = subAccountRes;
        data.forEach((label) => {
          const {
            firstClassifyName,
            secondClassifyName,
            labelNameI18n,
            labelValue,
            mustSelectAndReadOnly
          } = label;

          if (mustSelectAndReadOnly) {
            mustCheckedKeys.push(labelValue);
          }

          if (!allAuthKeys.includes(firstClassifyName)) {
            allAuthKeys.push(firstClassifyName);
          }

          if (!allAuthKeys.includes(secondClassifyName)) {
            allAuthKeys.push(secondClassifyName);
          }

          if (!allAuthKeys.includes(labelValue)) {
            allAuthKeys.push(labelValue);
          }

          // if (edit && this.newRole.roleLabels && this.newRole.roleLabels.includes(labelValue)) {
          //   if (!this.newRole.roleLabels.includes(firstClassifyName)) {
          //     this.newRole.roleLabels.push(firstClassifyName);
          //   }
          //   if (!this.newRole.roleLabels.includes(secondClassifyName)) {
          //     this.newRole.roleLabels.push(secondClassifyName);
          //   }
          // }

          if (temp[firstClassifyName]) {
            if (temp[firstClassifyName].children[secondClassifyName]) {
              temp[firstClassifyName].children[secondClassifyName].children[labelValue] = {
                title: labelNameI18n,
                key: labelValue,
                mustSelectAndReadOnly
              };
            } else {
              temp[firstClassifyName].children[secondClassifyName] = {
                title: secondClassifyName,
                key: secondClassifyName,
                children: {
                  [labelValue]: {
                    title: labelNameI18n,
                    key: labelValue,
                    mustSelectAndReadOnly
                  }
                }
              };
            }
          } else {
            temp[firstClassifyName] = {
              title: firstClassifyName,
              key: firstClassifyName,
              children: {
                [secondClassifyName]: {
                  title: secondClassifyName,
                  key: secondClassifyName,
                  children: {
                    [labelValue]: {
                      title: labelNameI18n,
                      key: labelValue,
                      mustSelectAndReadOnly
                    }
                  }
                }
              }
            };
          }
        });

        Object.values(temp)
          .forEach((first) => {
            const {
              children,
              key,
              title
            } = first;
            const leaf = {
              children: [],
              key,
              title
            };
            Object.values(children)
              .forEach((second) => {
                const {
                  children: children2,
                  key: key2,
                  title: title2
                } = second;
                const leaf2 = {
                  children: [],
                  key: key2,
                  title: title2
                };
                leaf.children.push(leaf2);

                Object.values(children2)
                  .forEach((third) => {
                    const {
                      key: key3,
                      title: title3,
                      mustSelectAndReadOnly
                    } = third;
                    const leaf3 = {
                      key: key3,
                      title: title3,
                      class: mustSelectAndReadOnly ? 'mustSelectAndReadOnly' : ''
                    };
                    leaf2.children.push(leaf3);
                  });
              });

            tempTreeData.push(leaf);
          });
      }

      tempTreeData.forEach((leaf) => {
        let leafNum = 0;
        leaf.children.forEach((leaf2) => {
          let leaf2Num = 0;
          leaf2.children.forEach((leaf3) => {
            if (leaf3.class === 'mustSelectAndReadOnly') {
              leaf2Num += 1;
            }
          });

          if (leaf2Num === leaf2.children.length) {
            leaf2.class = 'mustSelectAndReadOnly';
            leafNum += 1;
          }
        });

        if (leafNum === leaf.children.length) {
          leaf.class = 'mustSelectAndReadOnly';
        }
      });

      this.treeData = tempTreeData;
      this.allAuthKeys = allAuthKeys;
      this.mustCheckedKeys = [...mustCheckedKeys];
      this.checkedKeys = [...mustCheckedKeys];
      if (edit) {
        this.checkedKeys = this.newRole.roleLabels;
      }
    },
    handleQuery() {
      this.roleFilterInfo = {
        query: this.queryForm.text
      };
    },
    handleClickAddBtn() {
      this.showAddNewRoleModal = true;
      this.getAllAuthLabel();
    },
    async handleDeleteRole(role) {
      const data = { roleId: role.id };
      const res = await this.$services.deleteRole({
        data,
        msg: '删除角色成功'
      });
      if (res.success) {
        await this.getRoleList();
      }
    },
    async getRoleList() {
      const roleListRes = await this.$services.listRole();
      if (roleListRes.success) {
        this.roleList = roleListRes.data;
        this.generateSelectOptions();
      }
    },
    generateSelectOptions() {
      const temp = [];
      const tempMap = {};
      this.roleList.forEach((role) => {
        const data = {
          name: role.roleName,
          value: role.id
        };
        temp.push(data);
        tempMap[data.value] = data;
      });
      this.selectOptions = temp;
      this.roleListMap = tempMap;
    }
  },
  created() {
    this.getRoleList();
  }
};
</script>

<style lang="less" scoped>
.new-role-modal {
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .auth {
    .title {
      margin-bottom: 10px;
    }

    .all {
      height: 30px;
      width: 100%;
      background: #F3F3F3;
      line-height: 30px;
      padding-left: 10px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-bottom: none;
    }

    .content {
      width: 500px;
      height: 270px;
      border: 1px solid rgba(218, 218, 218, 1);
      overflow: scroll;
    }
  }

}
</style>
